.edit-humidor-cover-photo-btn-overlay.show {
  display: block;
}
.edit-humidor-cover-photo-btn-overlay {
  display: none;
  position: absolute;
  top: 94px;
  right: 20px;
  width: 180px;
  height: 26px;
  line-height: 26px;
  border-radius: 13px;
  background-color: rgba(256, 256, 256, 0.8);
  text-align: center;
  color: #2b2b2b;
  cursor: pointer;
}

@media screen and (max-width: 766px) {
  .edit-humidor-cover-photo-btn-overlay {
    top: calc(74px + env(safe-area-inset-top));
  }
  #humidor-filter {
    position: sticky;
    top: calc(54px + env(safe-area-inset-top));
    z-index: 1001;
  }
}

.rui-night-mode .MuiButton-outlined {
  border: 1px solid #cfcfcf !important;
}
